import React, { useState, useContext } from 'react';
import { Typography, Card, CardContent, Button, Modal } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { host, payment } from '../../utils/constants'
import axios from 'axios'
import { Loading } from '../common'
import { AuthContext } from '../../context';
import { useStripe } from '@stripe/react-stripe-js';
import { Controls } from '../general';


const useStyles = makeStyles((theme) =>
    createStyles({
        cntr: {
            textAlign: "center"
        },
        card1: {
            borderRadius: "0px",
            boxShadow: "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 6px 0px rgba(0,0,0,0.12)",
            width: "250px"
        },
        cardGrid: {
            width: "100%"
        },
        gridSpacing: {
            padding: theme.spacing(1),
        },
        link: {
            textDecoration: "none"
        },
        tableHeading: {
            textTransform: "uppercase"
        },
        table: {
            padding: theme.spacing(1),
            width: "56%"
        },
        mainImg: {
            width: "60%",
        },
        imgDiv: {
            marginTop: "3%",
            textAlign: "center",
            borderBottom: "1px solid #dadce0",
        },
        authorName: {
            color: "gray",
            fontStyle: "italic"
        },
        programs: {
            marginTop: "5%"
        },
        grid1: {
            // margin: "auto"
            padding: theme.spacing(1),
        },
        card2: {
            borderRadius: "0px",
            border: "1px solid rgba(0,0,0,.125)",
            boxShadow: "0px 0px 0px 0px white !important",
            "&:hover": {
                boxShadow: " 0 .5rem 1rem rgba(0,0,0,.15)!important"
            },
            padding: "40px"
        },
        media: {
            height: 200,
            borderBottom: "1px solid rgba(0,0,0,.125)",
        },
        paper: {
            borderRadius: "0px",
            border: "1px solid rgba(0,0,0,.125)",
            boxShadow: "0",
            "&:hover": {
                boxShadow: " 0 .5rem 1rem rgba(0,0,0,.15)!important"
            }
        },
        ageGrp: {
            marginTop: "0px",
            fontSize: "12px",
            color: "gray"
        },
        programName: {
            margin: "0px"
        },
        sec2: {
            margin: "25px",
            padding: "20px",
            borderBottom: "1px solid #dadce0",
        },
        img2: {
            height: "250px"
        },
        textRight: {
            textAlign: "right"
        },
        content: {
            padding: "10px",
            paddingBottom: "0px !important"
        },
        discount: {
            color: "gray",
            fontSize: "10px"
        },
        center: {
            textAlign: "center",
            width: "10%",
            margin: "auto",
            paddingTop: "10%",
            color: "white"
        },
        p25: {
            padding: "25px"
        },
        ctaEnroll: {
            borderRadius: "0px",
            padding: "10px 20px",
            backgroundColor: "#34a853",
            color: "white",
            '&:hover': {
                borderRadius: "0px",
                backgroundColor: "#34a853",
                color: "white",
            }
        }
    }))

function Enroll(props) {
    const classes = useStyles();

    const [pLoading, setPLoading] = useState(true)
    const [showCoupon, setShowCoupon] = useState(false);
    const [couponApplied, setCouponApplied] = useState(false)
    const [coupon, setCoupon] = useState("");
    const [cLoading, setCLoading] = useState(false)
    const [message, setMessage] = useState("")
    const stripe = useStripe();
    const { open, handleModalClose, loading, programState, setAlert } = props
    const { user } = useContext(AuthContext)
    const [pay, setPay] = useState({
        name: '',
        description: '',
        images: [''],
        amount: 0,
        currency: '',
        quantity: 0,
    })
    const [couponDetails, setCouponDetails] = useState({
        couponType: "",
        discount: ""
    })
    const [error, setError] = useState("")
    const [errors, setErrors] = useState({
        couponCode: ""
    })
    
    const createOrder = (e) => {
        e.preventDefault()
        const payUrl = `${host}${payment}`
        const payData = {}
        if (couponDetails && couponApplied) {
           let discount = `${(parseInt(programState.price))
                    -
                    (couponDetails.couponType === "flat"
                        ? parseInt(couponDetails.discount)
                        : (parseInt(programState.price) * parseInt(couponDetails.discount) / 100))  }`;
            payData['coupon'] = coupon;
            payData["price"] = programState.price
            payData["programId"] = programState._id;
            payData['currency'] = "GBP"
            payData['studentId'] = user._id
            payData["amount"] = `${discount}`;
            payData["lineItems"] = [{
                quantity: 1,
                currency: 'gbp',
                name: programState.name,
                description: programState.name,
                amount: `${discount * 100 }`
            }]
            
        }
        else {
            let amt = (isNaN(parseInt(programState.price)) ? 0 : parseInt(programState.price))
            payData["price"] = programState.price
            payData["programId"] = programState._id;
            payData['currency'] = "GBP"
            payData['studentId'] = user._id
            payData["amount"] = `${amt}`;
            payData["lineItems"] = [{
                quantity: 1,
                currency: 'gbp',
                name: programState.name,
                description: programState.name,
                amount: `${amt * 100}`
            }]
        }
        setPLoading(true)
        axios.post(payUrl, payData)
            .then((res) => {

                if (res.data.status) {

                    handleModalClose()
                    setAlert({
                        success: res.data.message,
                        error: ""
                    })
                } else {
                    setPLoading(false)
                    setPay(res.data)
                    stripe.redirectToCheckout({
                        sessionId: res.data.id,
                    }).then((res) => console.log(res))
                        .catch((err) => console.log(err))
                }
            })
    }
    const applyCoupon = (e) => {
        e.preventDefault();
        const url = `${host}${payment}coupon`;
        //TODO send program id for checks
        const data = {
            couponCode: coupon,
        }

        setCLoading(true)
        axios.post(url, data)
            .then(res => {
                setCLoading(false)
                setShowCoupon(false)
                setCouponApplied(true)
                setMessage(res.data.message)
                setCouponDetails({
                    couponType: res.data.couponType,
                    discount: res.data.discount
                })
                setTimeout(() => {
                    setMessage("")
                }, 4000);
            })
            .catch(err => {
                setCLoading(false)
                if (err.response) {
                    if (err.response.data.error) {
                        setErrors({couponCode:err.response.data.error})
                    }
                }
            })
    }

 
    return (
        <>

            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Card className={classes.card2}>
                    <CardContent>
                        {loading
                            ?
                            <div className="text-center">
                                <Typography>Fetching Payment Details </Typography>
                                <br />
                                <Loading />
                            </div>
                            : showCoupon
                                ? <>
                                    <form onSubmit={applyCoupon} className="text-center">
                                        <Controls.Input
                                            name="couponCode"
                                            label="Apply Coupon"
                                            onChange={(e) => setCoupon(e.target.value)}
                                            type="text"
                                            error={errors.couponCode}
                                        />
                                        <br />
                                        <br />
                                        <Controls.Button
                                            text="Cancel"
                                            onClick={() => setShowCoupon(false)} />
                                        <Controls.Button
                                            type="submit"
                                            text="Submit" />
                                    </form>
                                </>
                                : <>
                                    < form onSubmit={createOrder}>
                                        <Typography align="center" variant="h4">Enroll Now</Typography>
                                        <br />
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ padding: "5px" }}>Program Name:</td>
                                                        <td style={{ padding: "5px" }}>
                                                            <strong className={classes.p25}>
                                                                {programState.name}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                    {!couponApplied ?

                                                        <tr >

                                                            <td  >
                                                                <div style={{ cursor: "pointer", textDecoration: "underline", color: "blue", textAlign: 'center' }}>
                                                                    <p onClick={() => setShowCoupon(true)}>Apply Coupon</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        :
                                                        <tr>
                                                            <td style={{ padding: "5px" }} >Coupon Discount:</td>
                                                            <td style={{ padding: "5px" }} >

                                                                <strong className={classes.p25}>
                                                                    {couponDetails.couponType === "flat"
                                                                        ? `** ${couponDetails.couponType} £${couponDetails.discount} off`
                                                                        : `** flat ${couponDetails.discount}% off`}
                                                                </strong>
                                                            </td>
                                                        </tr>}
                                                    <tr>
                                                        <td style={{ padding: "5px" }} >Total:</td>
                                                        <td style={{ padding: "5px" }} >
                                                            <strong className={classes.p25}>
                                                                £ {parseInt(programState.price)
                                                                    - (couponApplied
                                                                        ? couponDetails.couponType === "flat"
                                                                            ? parseInt(couponDetails.discount)
                                                                            : (parseInt(programState.price) * parseInt(couponDetails.discount) / 100)
                                                                        : 0)}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br />




                                        <br />
                                        <div style={{ textAlign: "center" }}>
                                            <Button type="submit" className={classes.ctaEnroll}>Enroll Now</Button>
                                            {/* <Button color="secondary" size="large" variant="contained"

                                            onClick={handleCancel}>Cancel</Button>
                                        <Button type="submit" size="large" variant="contained">Checkout</Button> */}
                                        </div>
                                    </form>
                                </>}
                    </CardContent>
                </Card>
            </Modal >
        </>
    )
}



export default Enroll