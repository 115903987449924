import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function CustomizedSnackbars(props: any) {
    const classes = useStyles();
    const { open, handleClose, alert } = props
    // const [open, setOpen] = React.useState(false);

    // const handleClick = () => {
    //   setOpen(true);
    // };

    // const handleClose = (event:any, reason:any) => {
    //   if (reason === 'clickaway') {
    //     return;
    //   }

    //   setOpen(false);
    // };

    return (
        <div className={classes.root}>
            {
                alert.success
                &&
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" >
                        {alert.success}
                    </Alert>
                </Snackbar>
            }
            {
                alert.error && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" > 
                    {alert.error}
                    </Alert>
                </Snackbar>
            }
        </div>
    );
}