import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
    Navbar, Footer
} from '../common';
import { Container, Grid, CardMedia, CircularProgress, Box, Typography, Paper, CardContent } from '@material-ui/core';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainDiv: {
            marginTop: isMobile ? "70px" : "100px"
        },
        paper: {
            borderRadius: "0px",
        },
        programPreviewImg: {
            height: 100
        },
        nameDiv: {
            marginLeft: "10px",
            marginRight: "10px"
        },
        name: {
            // textAlign: "center",
            marginTop: "5px",
            marginBottom: "5px",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        detailsDiv: {
            padding: "15px"
        },
        progress: {
            height: "10px",
            borderRadius: "5px"
        },
        control: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        upprCase: {
            textTransform: "uppercase",
            textAlign: "center"
        },
        circleProgress: {
            height: "80px",
            width: "80px"
        },
        grid1: {
            // borderBottom: "1px solid #dadce0",
            paddingBottom: "15px",
            marginBottom: "15px",
        },
        spacing: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        link: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            textDecoration: "none",
            paddingTop: "10px"
        },
    }),
);
export default function EnrolledPrograms(props: any) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [programs, setProgram] = useState([
        {
            ratings: 0,
            highlights: [""],
            name: "",
            description: "",
            programImage: "",
            ageGroup: "",
            enrolledOn: "",
            lastOpened: "",
            updatedOn: "",
            updatedBy: "",
            price: "",
            _id: "",
            duration: "",
            previewVideo: {
                title: "",
                videoUrl: "",
                type: "",
                index: 0,
                preview: false
            },
            content: [
                {
                    level: "",
                    completed: false,
                    content: [
                        {
                            title: "",
                            videoUrl: "",
                            type: "",
                            index: 0,
                            completed: false
                        }
                    ]
                }
            ]
        }
    ])

    const getProgress = (p: any) => {
        const totalLevels = p.content.length;
        let totalCompleted = p.content.filter((c: any) => c.completed).length;
        const percentage = (totalCompleted / totalLevels) * 100;
        return percentage;
    }

    const getCurrLevel = (p: any) => {
        const currLevel = p.content.filter((c: any) => !c.completed)[0]
        const currentConcept = currLevel.content.filter((c: any) => c.completed)[0]
        return [currLevel.level, currentConcept.title]
    }
    useEffect(() => {
        setLoading(true)
        setProgram([
            {
                "ratings": 5,
                "highlights": [" Intro to coding (Drag and Drop) ", "Sequences", " Algorithms", " Events", " Loops ", "Binary Strings", " Animation"],
                "enrolledOn": "2020-04-18T12:02:18.635Z",
                "updatedOn": "2020-04-25T08:31:02.854Z",
                "_id": "5e9aecfc4903513158ff3054",
                "name": "Einstein",
                "previewVideo": {
                    "title": "Introduction",
                    "videoUrl": "https://www.youtube.com/watch?v=5HaJPpihkBI",
                    "type": "video",
                    "preview": true,
                    "index": 0
                },
                "description": "Introduce Kids to the world of code! They learn the basics of code. Watch their creativity unlock by the end of this Program. Unleash the hidden creator in your kid!",
                "programImage": "https://res.cloudinary.com/dhznnpvlm/image/upload/v1587211517/kids-code-in/2020-04-18T12:05:15.439Z.png",
                "lastOpened": "2020-04-25T08:31:02.854Z",
                "updatedBy": "5e650e7396bc9843d3dcbb1c",
                "ageGroup": "5-7 years",
                "price": "£3000",
                "duration": "9.5 hours",
                "content": [
                    {
                        "level": "Level 1",
                        "completed": true,
                        "content": [{
                            "title": "Introduction",
                            "videoUrl": "https://www.youtube.com/watch?v=5HaJPpihkBI",
                            "type": "video",
                            "completed": true,
                            "index": 0
                        },
                        {
                            "title": "Getting Started",
                            "videoUrl": "https://www.youtube.com/watch?v=EPylVlpKpZA",
                            "type": "video",
                            "completed": true,
                            "index": 1
                        },
                        {
                            "title": "Setting Up Environment",
                            "videoUrl": "https://www.youtube.com/watch?v=IdfR3S2yqJY",
                            "type": "video",
                            "completed": true,
                            "index": 2
                        },
                        {
                            "title": "Introduction to Blockly.",
                            "type": "video",
                            "completed": true,
                            "videoUrl": "https://www.youtube.com/watch?v=wDJua9hgyZM",
                            "index": 3
                        },]
                    },
                    {
                        "level": "Level 2",
                        "completed": false,
                        "content": [{
                            "title": "Introduction",
                            "videoUrl": "https://www.youtube.com/watch?v=5HaJPpihkBI",
                            "type": "video",
                            "completed": true,
                            "index": 0
                        },
                        {
                            "title": "Getting Started",
                            "videoUrl": "https://www.youtube.com/watch?v=EPylVlpKpZA",
                            "type": "video",
                            "completed": false,
                            "index": 1
                        },
                        {
                            "title": "Setting Up Environment",
                            "videoUrl": "https://www.youtube.com/watch?v=IdfR3S2yqJY",
                            "type": "video",
                            "completed": false,
                            "index": 2
                        },
                        {
                            "title": "Introduction to Blockly.",
                            "type": "video",
                            "completed": false,
                            "videoUrl": "https://www.youtube.com/watch?v=wDJua9hgyZM",
                            "index": 3
                        },]
                    },
                    {
                        "level": "Level 3",
                        "completed": false,
                        "content": [{
                            "title": "Introduction",
                            "videoUrl": "https://www.youtube.com/watch?v=5HaJPpihkBI",
                            "type": "video",
                            "completed": false,
                            "index": 0
                        },
                        {
                            "title": "Getting Started",
                            "videoUrl": "https://www.youtube.com/watch?v=EPylVlpKpZA",
                            "type": "video",
                            "completed": false,
                            "index": 1
                        },
                        {
                            "title": "Setting Up Environment",
                            "videoUrl": "https://www.youtube.com/watch?v=IdfR3S2yqJY",
                            "type": "video",
                            "completed": false,
                            "index": 2
                        },
                        {
                            "title": "Introduction to Blockly.",
                            "type": "video",
                            "completed": false,
                            "videoUrl": "https://www.youtube.com/watch?v=wDJua9hgyZM",
                            "index": 3
                        },]
                    }
                ]
            }])
        setTimeout(() => {
            setLoading(false);
        }, 2000)
    }, [])
    const getLevelPerc = (l: any) => {
        const completed = l.content.filter((c: any) => c.completed);
        const perc = (completed.length / l.content.length) * 100;
        return perc
    }
    return (
        <>
            <Navbar {...props} />
            <Container className={classes.mainDiv}>
                <h1>Enrolled Programs</h1>
                {loading ? <div className="text-center"><CircularProgress /></div> :
                    <> {
                        programs.map((p, i) => {
                            return <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid item xs={12} sm={3}>
                                        <CardMedia
                                          component="img"
                                            src={p.programImage}
                                            alt={p.name}
                                            className={classes.programPreviewImg}
                                        />
                                        <CardContent>
                                            <h2 className={classes.name}><strong>{p.name}</strong></h2>
                                            <Grid container>
                                                <Grid xs={12} sm={6} className={classes.spacing} item>
                                                    <small><strong>Last Opened</strong></small><br />
                                                    <small style={{ fontSize: "11px" }}>{new Date(p.lastOpened).toLocaleString()}</small>
                                                </Grid>
                                                <Grid xs={12} sm={6} className={classes.spacing} item>
                                                    <small><strong>Current Level</strong></small><br />
                                                    <small style={{ fontSize: "11px" }}>{getCurrLevel(p)[0]} - {getCurrLevel(p)[1]}</small>
                                                </Grid>
                                                <Link className={classes.link} to={`/program/${p._id}/learn`}><strong>Continue</strong></Link>
                                            </Grid>
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <div className={classes.detailsDiv}>
                                            <small><strong>Progress</strong></small>
                                            <LinearProgressWithLabel
                                                value={getProgress(p)}
                                                className={classes.progress}
                                            />
                                            <Grid className={classes.grid1} container>
                                                {p.content.map((c, i) => {
                                                    return <Grid key={i} spacing={10} item>
                                                        <div className={classes.control}>
                                                            <p className={classes.upprCase}><strong>{c.level}</strong></p>
                                                            <CircularProgressbar className={classes.circleProgress} value={getLevelPerc(c)} text={`${getLevelPerc(c)}%`} />
                                                        </div>
                                                    </Grid>
                                                })}
                                            </Grid>
                                            <br />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        })
                    }
                    </>
                }
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Footer />
        </>
    )
}