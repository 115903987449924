import { GET_CONCEPTS_PROGRAMID, RESET_CONCEPTS_PROGRAMID } from '../actions/types'

const initialState = {
    concepts: [],
    isConceptsLoading : true
}

export const conceptsReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case GET_CONCEPTS_PROGRAMID:
            return {
                ...state,
                concepts: action.payload,
                isConceptsLoading : false
            }
        case RESET_CONCEPTS_PROGRAMID:
            return {
                ...state,
                concepts: [],
                isConceptsLoading : true
            }
        default:
            return state;
    }
}