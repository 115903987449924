import React, { useState } from "react";
import Axios from "axios";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab/";
import {
  Card,
  CardContent,
  LinearProgress,
  Button,
  Typography,
} from "@material-ui/core/";
import { isMobile } from "react-device-detect";

import { Navbar } from "../common";
import { host, account, register, teacher } from "../../utils/constants";
import CustomTextField from "../common/CustomTextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      margin: "auto",
      width: "350px",
      textAlign: "center",
      marginTop: isMobile ? "20%" : "8%",
    },
    registerBtn: {
      background: "#00ccb0",
      color: "white",
      // boxShadow: "5px 5px 0px 0px #0092CC",
      borderRadius: "0px",
      outline: "1px solid black",
      "&:hover": {
        backgroundColor: "#00ccb0",
        color: "white !important",
        boxShadow: "0px 0px 0px 0px #0092CC",
      },
    },
    heading: {
      fontWeight: 900,
      textTransform: "uppercase",
    },
    centerForm: {
      margin: "auto",
    },
  })
);

const TeacherRegister = (props: any) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    mobileNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    mobileNumber: "",
  });

  // useEffect(() => {
  //     const query = new URLSearchParams(props.location.search);

  //     if (query.get("roomId")) {
  //         let rId: any = query.get("roomId")
  //         localStorage.setItem("userRoomId", rId)
  //     }
  // })

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const url = `${host}${account}${teacher}${register}`;
    setErrors({
      name: "",
      email: "",
      password: "",
      password2: "",
      mobileNumber: "",
    });

    setLoading(true);

    Axios.post(url, formData)
      .then((res) => {
        setLoading(false);
        setMessage(res.data.message);
      })
      .catch((err) => {
        setLoading(false);

        if (err.response) {
          if (err.response.data.message) {
            setError(err.response.data.message);
          } else {
            setErrors({ ...err.response.data });
          }
        } else {
          if (err.message === "Network Error") {
            setError(`${err.message} - Please check your internet connection`);
          } else {
            setError(err.message);
          }
        }
      });
  };

  return (
    <>
      <Navbar {...props} />
      <Card className={classes.center}>
        {loading && <LinearProgress color="secondary" />}
        <CardContent>
          {message && <Alert severity="success">{message}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
          <Typography className={classes.heading} variant="h5" align="center">
            Teacher Sign Up
          </Typography>
          <br />
          <form onSubmit={handleSubmit}>
            <CustomTextField
              className="m-c"
              onChange={handleChange}
              type="text"
              placeholder="Name"
              name="name"
              error={errors.name}
            />
            <br />
            <CustomTextField
              className="m-c"
              onChange={handleChange}
              type="email"
              placeholder="Email"
              name="email"
              error={errors.email}
            />
            <br />
            <CustomTextField
              className="m-c"
              onChange={handleChange}
              type="mobileNumber"
              placeholder="Mobile Number"
              name="mobileNumber"
              error={errors.mobileNumber}
            />
            <br />
            <CustomTextField
              className="m-c"
              onChange={handleChange}
              type="password"
              placeholder="Password"
              name="password"
              error={errors.password}
            />
            <br />
            <CustomTextField
              className="m-c"
              onChange={handleChange}
              type="password"
              placeholder="Confirm Password"
              name="password2"
              error={errors.password2}
            />
            <br />
            <Button
              className={classes.registerBtn}
              type="submit"
              variant="contained"
              color="primary"
            >
              Sign Up
            </Button>
            <br />
            <p style={{ fontSize: "12px" }}>
              By signing up for ttrainer.org, you agree to ttrainer.org's Terms
              of Service & Privacy Policy.
            </p>
            <br />
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default TeacherRegister;
