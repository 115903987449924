import React, { useEffect, useContext, Suspense } from 'react';
import Axios from 'axios';
import jwt_decode from 'jwt-decode';
import {
    BrowserRouter as Router,
    Route, Switch, Redirect, useHistory
} from "react-router-dom";
import {
    Home, ProgramDetails, Program, PaymentFailure, PaymentSuccess, NotFound
} from './components/pages';
import {
    Login, Register,
    ForgotPassword, ResetPassword,
    VerifyEmail, GenerateToken
} from './components/auth';
import { PrivateRoute } from './components/private'
//admin
// import AdminDashboard from './components/admin/Dashboard';
// import UserDashboard from './components/user/UserDashboard';
// import TeacherDashboard from './components/teacher/Dashboard';
//importing Components
import { AuthContext, StyleContext } from './context/';
import { setAuthToken } from './utils/utils';
import TeacherRegister from './components/auth/TeacherRegister';
import {admin, host} from './utils/constants';
const UserDashboard = React.lazy(() => import('./components/user/UserDashboard'));
const AdminDashboard = React.lazy(() => import('./components/admin/Dashboard'));
const TeacherDashboard = React.lazy(() => import('./components/teacher/Dashboard'));
// axiosGlobalErrorHandlerConfig()
const Routes = (props: any) => {
    // const [auth, setAuth] = useState({});
    const { user, updateUser, isAuthenticated } = useContext(AuthContext);

    const { style } = useContext(StyleContext);

    let history = useHistory();

    const closeUserSession = async () =>{
        try {
            const url = `${host}${admin}usersession/${user.sessionId}`;
    
            await Axios.put(url, {endTime: new Date()});
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // Check token
        if (localStorage.jwtToken) {
            // Set auth token header auth
            setAuthToken(localStorage.jwtToken);
            // Decode token and get user info and exp
            const decoded: any = jwt_decode(localStorage.jwtToken);
            // Set user and isAuthenticated

            // Check for expire token
            const currentTime = Date.now() / 1000;

            if (decoded.exp < currentTime) {
                // Logout User
                // TODO: Clear current profile
                // store.dispatch(clearCurrentProfile);
                localStorage.removeItem('jwtToken')
                // Redirect to login
                window.location.href = "/login";
            } else {
                updateUser({ user: decoded, isAuthenticated: true })
            }

        }

        return () => {
            if (localStorage.jwtToken) {
                closeUserSession();
            }
        }
    }, []);

    return (
        <Suspense fallback={<div></div>}>
            <div style={{ backgroundColor: style.rootBackgroundColor, height: style.rootBackgroundHeight }}>
                <Router>
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route exact path='/program' component={Program} />
                        <Route path="/program/:id" exact component={ProgramDetails} />
                        <Route path='/login' exact component={Login} />
                        <Route path='/register' exact component={Register} />
                        <Route path='/teacher/register' exact component={TeacherRegister} />
                        <Route path='/forgotpassword' exact component={ForgotPassword} />
                        <Route path='/reset-password/:id' exact component={ResetPassword} />
                        <Route exact path="/verificationemail" component={GenerateToken} />
                        <Route exact path="/verifyemail/:token" component={VerifyEmail} />
                        <Route exact path="/payment/success/:id" render={() => <PaymentSuccess />} />
                        <Route path="/payment/failed" exact component={PaymentFailure} />
                        {/* Student Dashboard */}
                        <Route path="/student" component={UserDashboard} />

                        {/* Teacher Dashboard */}
                        <Route path="/teacher" component={TeacherDashboard} />

                        {/* Admin Dashboard */}

                        <Route path="/admin/" component={AdminDashboard} />


                        <Route render={NotFound} />

                    </Switch>
                </Router>
            </div>
        </Suspense>
    )
}

export default Routes;