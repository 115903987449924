import {GET_PROGRAMS,GET_PROGRAM ,RESET_PROGRAM ,  GET_INSTRUCTOR_PROFILE} from './types'
import axios from 'axios'
import {host , programsApi , userProfile} from '../utils/constants'

export const getPrograms = () => async (dispatch: any) => {
    const res = await axios.get(`${host}${programsApi}`)
    dispatch({
        type:GET_PROGRAMS,
        payload: res.data
    })
}


export const getProgram = (id:any) => async (dispatch: any) => {
    const res = await axios.get(`${host}${programsApi}${id}`)
    dispatch({
        type:GET_PROGRAM,
        payload: res.data
    })
}


export const getInstructorProfile = (id:any) => async (dispatch: any) => {
    const res = await axios.get(`${host}${userProfile}${id}`)
  
    dispatch({
        type: GET_INSTRUCTOR_PROFILE,
        payload: res.data
    })
}

export const resetProgram = (dispatch: any) => {
    
    dispatch({
        type:RESET_PROGRAM ,
       
    })
}
