import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Footer, Loading } from "../common";
import { Contactus } from "../general";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Truncate from "react-truncate";
import { RootState } from "../../reducers";
import { getPrograms } from "../../actions/index";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainDiv: {
      marginTop: isMobile ? "70px" : "100px",
    },
    mainImg: {
      width: isMobile ? "100%" : "60%",
    },
    imgDiv: {
      marginTop: "3%",
      textAlign: "center",
      borderBottom: "1px solid #dadce0",
    },
    authorName: {
      color: "gray",
      fontStyle: "italic",
    },
    programs: {
      marginTop: isMobile ? "20%" : "8%",
    },
    grid1: {
      padding: "10px",
    },
    card: {
      borderRadius: "0px",
      border: "1px solid rgba(0,0,0,.125)",
      boxShadow: "0px 0px 0px 0px white !important",
      position: "relative",
      "&:hover": {
        boxShadow: " 0 .5rem 1rem rgba(0,0,0,.15)!important",
      },
    },
    cardBadge: {
      position: "absolute",
      top: "11px",
      right: "-14px",
      padding: "0.5rem",
      width: "10rem",
      background: "#3949ab",
      color: "white",
      textAlign: "center",
      fontFamily: "'Roboto', sans-serif",
      boxShadow: "4px 4px 15px rgba(26, 35, 126, 0.2)",
    },
    media: {
      height: 200,
      borderBottom: "1px solid rgba(0,0,0,.125)",
    },
    paper: {
      borderRadius: "0px",
      border: "1px solid rgba(0,0,0,.125)",
      boxShadow: "0",
      "&:hover": {
        boxShadow: " 0 .5rem 1rem rgba(0,0,0,.15)!important",
      },
    },
    ageGrp: {
      marginTop: "0px",
      fontSize: "12px",
      color: "gray",
    },
    programName: {
      margin: "0px",
    },
    sec2: {
      margin: isMobile ? "0" : "25px",
      padding: isMobile ? "0" : "20px",
      borderBottom: "1px solid #dadce0",
    },
    img2: {
      height: isMobile ? "150px" : "250px",
    },
    textRight: {
      textAlign: "right",
    },
    content: {
      padding: "10px",
      paddingBottom: "0px !important",
    },
    discount: {
      color: "gray",
      fontSize: "10px",
    },
    h3: {
      // marginBottom: "5px"
    },
    label: {
      margin: "0",
      padding: ".05em .2em",
      // minWidth: "3em",
      width: "100px",
      textAlign: "center",
      color: "white",
      float: "left",
      marginTop: "2px",
      // fontSize: "12px"
    },
    textHeading: {
      textTransform: "uppercase",
      color: "#0000FF !important",
    },
    pText: {
      marginTop: "1rem !important",
      fontSize: "18px  !important",
    },
    piText: {
      marginTop: "1rem !important",
      fontSize: "18px  !important",
      fontStyle: "italic !important",
    },
  })
);
export default function Home(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const programsState = useSelector(
    (state: RootState) => state.programsState.programs
  );
  const isProgramsLoading = useSelector(
    (state: RootState) => state.programsState.isProgramsLoading
  );
  useEffect(() => {
    dispatch(getPrograms());
  }, [dispatch]);

  return (
    <>
      <Navbar {...props} />
      <Container className={classes.mainDiv}>
        <div className={classes.imgDiv}>
          <img
            className={classes.mainImg}
            src="https://res.cloudinary.com/ddbapnomm/image/upload/v1700418704/ttrainer.org_Logo_n22bzu.webp"
            alt="learn coding"
          />
          <h1>
            TTrainer.org empowers undergraduate in-service teachers to enhance
            their skills and rediscover the joy of teaching through skill based
            training.{" "}
          </h1>
          <p>
            ttrainer.org is not for profit dedicated to enhancing the skills of
            undergraduate in-service teachers through skill-based training
            aligned with National Education Policy Guidelines. They also offer
            experienced educators opportunities in teacher training. The vision
            is to empower the teaching community and rediscover the joy of
            teaching. For more details visit ttrainer.org
          </p>
          <br />
        </div>
        <div className={classes.programs}>
          <h1 className="text-center">Learn on your schedule</h1>
          {isProgramsLoading ? (
            <Loading />
          ) : (
            <Grid container>
              {programsState.map((p: any, i: any) => {
                return (
                  <Grid
                    className={classes.grid1}
                    key={p._id}
                    xs={12}
                    sm={4}
                    item
                  >
                    <Link className="link" to={`/program/${p._id}`}>
                      <Card className={classes.card}>
                        <CardMedia
                          component="img"
                          className={classes.media}
                          src={p.programImage}
                          alt={p.name}
                        />

                        <CardContent className={classes.content}>
                          <h3 className={classes.programName}>{p.name}</h3>
                          <p className={classes.ageGrp}>{p.ageGroup}</p>
                          <Truncate lines={3} trimWhitespace>
                            <p>{p.description}</p>
                          </Truncate>

                          <div className={classes.textRight}>
                            <p
                              className={classes.label}
                              style={{ backgroundColor: "rgb(63, 102, 218)" }}
                            >
                              {p.isTrial ? "Free" : "Premium"}
                            </p>

                            <h3 className={classes.h3}>
                              {" "}
                              <span className={classes.discount}>
                                <s>£3000</s>
                              </span>{" "}
                              {p.currency} <strong>{p.price}</strong>
                            </h3>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
        <br />
        <br />
        {/* <Contactus /> */}
      </Container>
      <Footer />
    </>
  );
}

export function Program(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const programsState = useSelector(
    (state: RootState) => state.programsState.programs
  );
  const isProgramsLoading = useSelector(
    (state: RootState) => state.programsState.isProgramsLoading
  );
  useEffect(() => {
    dispatch(getPrograms());
  }, [dispatch]);
  return (
    <>
      <Navbar {...props} />
      <Container>
        <div className={classes.programs}>
          <h1 className="text-center">Learn on your schedule</h1>
          {isProgramsLoading ? (
            <Loading />
          ) : (
            <Grid container>
              {programsState.map((p: any) => {
                return (
                  <Grid
                    className={classes.grid1}
                    key={p._id}
                    xs={12}
                    sm={6}
                    md={4}
                    item
                  >
                    <Link className="link" to={`/program/${p._id}`}>
                      <Card className={classes.card}>
                        <CardMedia
                          component="img"
                          src={p.programImage}
                          alt={p.name}
                          className={classes.media}
                        />
                        <CardContent className={classes.content}>
                          <h3 className={classes.programName}>{p.name}</h3>
                          <p className={classes.ageGrp}>{p.ageGroup}</p>
                          {p.description}
                          <div className={classes.textRight}>
                            <h3>
                              <span className={classes.discount}>
                                <s>£3000</s>
                              </span>{" "}
                              {p.currency} <strong>{p.price}</strong>
                            </h3>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
      </Container>
      <Footer />
    </>
  );
}
