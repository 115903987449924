import React from 'react';
import { Alert } from '@material-ui/lab/';




const AlertMessage = (props:any) => {
    return (
        <>
            {
                props.alert.success
                &&
                <Alert severity="success">{props.alert.success}</Alert>
            }
            {
                props.alert.error
                &&
                <Alert severity="error">{props.alert.error}</Alert>
            }
        </>
    )
}

export default AlertMessage;