import { GET_CONCEPTS_PROGRAMID,RESET_CONCEPTS_PROGRAMID } from './types'
import axios from 'axios'
import {host , conceptsApi, getConceptByProgramId } from '../utils/constants'

export const getConceptsByProgramId = (id:any) => async (dispatch: any) => {
    const res = await axios.get(`${host}${conceptsApi}${getConceptByProgramId}${id}`)
    dispatch({
        type:GET_CONCEPTS_PROGRAMID,
        payload: res.data
    })
}




export const resetConcepts = (dispatch: any) => {
    
    dispatch({
        type:RESET_CONCEPTS_PROGRAMID  
       
    })
}
