import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { host, programsApi, review as r } from '../../utils/constants';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { isMobile } from 'react-device-detect'
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const labels = {
  0.5: 'Awful, not what I expected at all',
  1: 'Awful, not what I expected at all',
  1.5: 'Awful / Poor',
  2: 'Poor, pretty disappointed',
  2.5: 'Poor / Average',
  3: 'Average, could be better',
  3.5: 'Average / Good',
  4: 'Good, what I expected',
  4.5: 'Good / Amazing',
  5: 'Amazing, above expectations!',
};

const useStyles = makeStyles((theme) => ({

  textField: {
    // width: '100',
  },
  title: {
    textAlign: 'center',
  },
  btn1: {
    backgroundColor: "#E61B1B",
    color: "white",
    borderRadius: "0px",
    position: isMobile ? "fixed" : "auto",
    bottom: isMobile ? "5px" : "auto",
    right: isMobile ? "5px" : "auto",
    borderRadius: isMobile ? "50px" : "0"
  },
  btn2: {
    backgroundColor: "#E61B1B",
    color: "white",
    borderRadius: "0px",
  },
  box: {
    padding: "15px",
    width: isMobile ? "275px" : "400px"
  },
  success: {
    color: "#094C59"
  }
}))

export default function Ratings() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(3);
  const [hover, setHover] = React.useState(-1);
  const [review, setReview] = React.useState("");
  const programId = useSelector((state) => state.editorState.currentProgramId);
  const [snackbar, setSnackbar] = React.useState(false);
  const [alert, setAlert] = React.useState({
    error: "",
    success: ""
  })
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submitForm = async (e) => {
    e.preventDefault();
    const formData = {
      rating: value,
      review
    }
    try {
      const res = await axios.post(`${host}${programsApi}${r}${programId}`, formData);
      setAlert({
        error: "",
        success: res.data.message
      })
    } catch (err) {
      setAlert({
        error: err.response.data.message,
        success: ""
      })
      setSnackbar(true)
    }
  }

  return (
    <div>
      <div>
        <Button className={classes.btn1} onClick={handleClickOpen}>
          Leave Review
      </Button> </div>
      <div>
        <Dialog

          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >

          <div className={classes.box} elevation={3}>
            <p className='close-button'>
              {' '}
              {/* <Button onClick={handleClose} color='primary' autoFocus>
                close
              </Button> */}
            </p>
            {alert.error && <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={snackbar}
              onClose={() => setSnackbar(false)}
            >
              <Alert severity="error">{alert.error}</Alert>
            </Snackbar>}
            {alert.success ? <div className={classes.title}>
              <h2 className={classes.success}>{alert.success}</h2>
            </div> :
              <div className={classes.title}>
                <h2>Please rate this program.</h2>
                <form onSubmit={submitForm} >
                  <Rating
                    name="feedback"
                    value={value}
                    size="large"
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                  />
                  {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
                  <Container maxWidth='lg'>
                    <TextField
                      onChange={(e) => setReview(e.target.value)}
                      placeholder='Tell us about your own personal experience taking this course. Was it a good match for you?'
                      fullWidth
                      multiline
                      rows={5}
                      margin='normal'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant='outlined'
                    />
                  </Container>
                  <div className={classes.title}>
                    <Button className={classes.btn2} size="large" type="submit">
                      Submit
                  </Button>
                  </div>
                </form>
              </div>
            }
          </div>
        </Dialog>
      </div>
    </div>
  )
}
