import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context";
import { YellowLogo } from "../../images";

import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Rating } from "../user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: "white",
      boxShadow:
        "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 6px 0px rgba(0,0,0,0.12)",
      // boxShadow: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    brandLogo: {
      height: isMobile ? "20px" : "30px",
    },
    link: {
      textDecoration: "none",
      color: "black",
      textTransform: "uppercase",
      fontFamily: " 'Open Sans', sans-serif",
      padding: "10px",
      fontSize: isMobile ? "10px" : "15px",
      "&:hover": {
        color: "gray !important",
      },
    },
    logoImg: {
      height: "30px",
      backgroundColor: "black",
    },
  })
);

export default function ButtonAppBar() {
  const classes = useStyles();
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const programId = useSelector(
    (state: RootState) => state.editorState.currentProgramId
  );
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link className="link" to="/">
              <img
                className={classes.brandLogo}
                src="https://res.cloudinary.com/ddbapnomm/image/upload/v1700418704/ttrainer.org_Logo_n22bzu.webp"
                alt="lms.ttrainer.org"
              />
            </Link>
          </Typography>
          {programId && <Rating />}
          {!isMobile && (
            <>
              <Link className={classes.link} to="/program">
                Programs & Pricing
              </Link>
            </>
          )}
          {isAuthenticated ? (
            <>
              <Link className={classes.link} to={`/${user.role}/dashboard`}>
                Dashboard
              </Link>
              <Link className={classes.link} onClick={logout} to="#">
                Logout
              </Link>
              {/* <IconButton color="inherit" onClick={logout} > */}
              {/* <img className={classes.logoImg} alt="ttrainer.org" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAC90lEQVRoge2ZO2gUQRjHvy8vIxgkYhpjJcY3CCJiEYKKgi98BV+FRiEYQVBBxCqFvY3apVFT2ikqCoopxEYjBB9JfKAJSaWgMVGiMflZ7AaPub3d2dzezoH3q25vZr75/5mZnW9mRUqUKFHUAAocAu4Cz4GbwAbXumIDdJDNFHDatTZrgF0BJqaZABpsY5UVUqgFB0LKKkSk2TaQayPzI8rrbAO5NtIbUf4mFRVBABXAceC6/zaqDanbAPzMsUYGgTlpap8WVQYcBvoDFm0XcB5YEdBuB/DVaPMRWJ22AQX2Ai9D3kCZfACuABsB9WPUAi3ABaAZqE7bxDbgmaWBIB4RMvXSMLAOeBIhsh/4YWHmDv7IuDCyNURYD95Gp8Bs//evCDNtToz4ZroMMb3AUaDcqHfEYlTGgMWujDT6Ij4BJ4CKgDrlQJ+FEfCmanlQXwUH2A1UhpS3WJqY5lya+q3wR8PcU6IYB1bNpL+s6ZAgLSKyJKLOFxGZJ/9SpVkicg04maP+qKq+TUhfNEAl3sYXRRtwKcaIPU7NhG+k1ULUAFAFVAOvi84I3v5h86Zqy2izFi8nKyojCywEDQBVRruLxWZkoYWgxHfyxA9WqjokIu8iqo0k3W9BAHYCf0JGZBiY61qnFXiHrN8hZq661mgN3ukv11F2EljvWmMWeCn7PWCL8X8T8C2HmR4Cks2ZEHmgAdpFpMkiVr2ILBeRcRE5qKq3M2KsEZH7Eny9c1ZVL9vJzQO8u9i4TAD7jTjL8G5GTL4D9fnqLNS91ogYd1aq2icijSJiJn01IpL3vlIII6Misl1VX5kFqjooIqcC2jzIt1ObhdYhIg9jxOxW1e6Q8j3G83sReRojvnvwst3Pxhppd60rNsA+w8QUsMi1rtgAtwwj6R6UkgCoIztlOeZaV2yAM4aJMaDGta7YAC8MI52uNcUGWBmwo292rSs2ZN+UDJHwrWJan96GRWQw47lTVSdT6jtZ8L5obQJuAEtd6ylR4n/jL4tXPk8rUx3uAAAAAElFTkSuQmCC" /> */}
              {/* Logout */}
              {/* </IconButton> */}
            </>
          ) : (
            <>
              <Link className={classes.link} to="/login">
                Login
              </Link>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
