import React, { useState } from 'react';
import Axios from 'axios';
import {
    createStyles, Theme, makeStyles
} from '@material-ui/core/styles';
import {
    Card, CardContent, Button,
    Typography, LinearProgress,
    Grid
} from '@material-ui/core';


import { host, account, contact } from '../../utils/constants';
import CustomTextField from '../common/CustomTextField';
import {CustomizedSnackbars} from '../general'
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => createStyles({
    center: {
        width: "100%",
        textAlign: "center",
        marginTop: isMobile ? "20%" : "7%",
        boxShadow: "0px 0px 0px 0px white !important",
       
    },
    card:{
        margin: "auto",
        borderRadius: "0px",
        border: "1px solid rgba(0,0,0,.125)",
        width: "80%"
    },
    loginBtn: {
        background: "#03DAC6",
        color: "white",
        // boxShadow: "5px 5px 0px 0px #03DAC6",
        borderRadius: "0px",
        outline: "1px solid black",
        '&:hover': {
            backgroundColor: "#03DAC6",
            color: "white !important",
            boxShadow: "0px 0px 0px 0px #03DAC6",
        }
    },
    heading: {
        fontWeight: 900,
        textTransform: "uppercase"
    }
}));

const ContactUs = (props: any) => {
    const classes = useStyles();
  
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    
    const [alert, setAlert] = useState({
        success: "",
        error: ""
    });
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });
    const [formData, setFormData] = useState({
        email: "",
        name: "",
        subject: "",
        message: "",
    });

   

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const url = `${host}${account}${contact}`
        setLoading(true);
        Axios.post(url, formData)
        .then((res: any) => {
            setOpen(true)
            setAlert({
                success: res.data.message,
                error: ""
            })
            setLoading(false);
        })
        .catch((err: any) => {
            setOpen(true)
            setAlert({
                success: "",
                error: err.response.statusText
            })
            setLoading(false);
            setErrors(err.response.data)

        })
    }
    const handleClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    return (
        <>
            <Grid className={classes.card} container>
            <CustomizedSnackbars open={open} alert={alert} handleClose={handleClose} />
                <Grid item xs={12}>
                    <Card className={classes.center}>
                        {loading && <LinearProgress color="secondary" />}
                        <CardContent>
                            <Typography className={classes.heading} variant="h4" align="center">Contact Us</Typography>
                            <br />
                            <form onSubmit={handleSubmit}>
                                <CustomTextField
                                    className="m-c"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder="Full Name"
                                    name="name"
                                    error={errors.name}
                                />
                                <br />
                                <CustomTextField
                                    className="m-c"
                                    type="email"
                                    onChange={handleChange}
                                    placeholder="Email"
                                    name="email"
                                    error={errors.email}
                                />
                                <br />
                                <CustomTextField
                                    className="m-c"
                                    type="text"
                                    onChange={handleChange}
                                    placeholder="Subject"
                                    name="subject"
                                    error={errors.subject}
                                />
                                <br />
                                <textarea
                                    className="form-control m-c"
                                    onChange={handleChange}
                                    placeholder="Message"
                                    name="message"
                                  
                                ></textarea>
                                <br />
                                <Button className={classes.loginBtn} type="submit" variant="contained" >Submit</Button>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default ContactUs;