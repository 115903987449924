import axios from 'axios';
import get from 'lodash/get';
import { host, conceptsApi, adminApi  } from '../../../utils/constants'

// action types
export const POST_VIMEO_START = 'vimeo/POST_VIMEO_START';
export const POST_VIMEO_SUCCESS = 'vimeo/POST_VIMEO_SUCCESS';
export const POST_VIMEO_FAILED = 'vimeo/POST_VIMEO_FAILED';
export const POST_VIMEO_PROGRESS = 'vimeo/POST_VIMEO_PROGRESS';
var instance = axios.create();
delete instance.defaults.headers.common['Authorization'];
// initial state
const initialState = {
  vimeoLink: undefined,
  uploading: false,
  uploadStatus: '',
  message : '',
  progress: 0
};

// reducer
export const videoData = (state = initialState, action) => {
  switch (action.type) {
    case POST_VIMEO_START:
      return {
        ...state,
        ...action.payload
      };

    case POST_VIMEO_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case POST_VIMEO_FAILED:
      return {
        ...state,
        ...action.payload
      };

    case POST_VIMEO_PROGRESS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
// actions
export const uploadVimeo = ({  ...postBody }) => {
  return async dispatch => {
    const success = async (videoUri, videoData, size, uploadLink, result) => {
      const apiResult = result.data;
      // check if upload success
      const checkUploadResult = await instance.head(uploadLink, {
        headers: {
          'Tus-Resumable': '1.0.0'
        }
      });

      const uploadLength = get(checkUploadResult, ['headers', 'upload-length']);
      const uploadOffset = get(checkUploadResult, ['headers', 'upload-offset']);
      

      if (uploadLength === uploadOffset) {
        const vimeoUrl = "https://player.vimeo.com/video/"
        let r = /(videos|video|channels|\.com)\/([\d]+)/
        let vid = (videoUri.match(r)[2])

        const data = {
          title: postBody.fd.title,
          category: postBody.fd.category,
          programId: postBody.fd.programId,
          description: postBody.fd.description,
          isPreview: postBody.fd.isPreview,
          videoType: postBody.fd.videoType,
          referenceMaterial: postBody.fd.referenceMaterial,
          referenceVideo: postBody.fd.referenceVideo,
          videoUrl : `${vimeoUrl}${vid}`
        }
        // complete upload
        const getVideoExternalLInk = await axios.post(
          `${host}${conceptsApi}`, data
        );
        dispatch({
          type: POST_VIMEO_SUCCESS,
          payload: {
            uploading: false,
            uploadStatus: 'success',
            message : getVideoExternalLInk.data.message
          }
        });
        postBody.history.push('/admin/dashboard/concepts')
        return apiResult;
      } else {
        // upload failed, resume upload?
        // console.log('should resume upload');
        return patchVimeoFunc(
          videoUri,
          uploadLink,
          videoData,
          size,
          uploadOffset
        );
      }
    };
    const failed = () => {
      dispatch({
        type: POST_VIMEO_FAILED,
        payload: {
          uploading: false,
          uploadStatus: 'failed'
        }
      });
      return null;
    };

    const patchVimeoFunc = async (
      videoUri,
      uploadLink,
      videoData,
      size,
      uploadOffset
    ) => {
      
      const result = await instance.patch(uploadLink, videoData, {
        headers: {
          'Tus-Resumable': '1.0.0',
          'Upload-Offset': uploadOffset || '0',
          'Content-Type': 'application/offset+octet-stream',
          'Accept': 'application/vnd.vimeo.*+json;version=3.4'
         
        },
        onUploadProgress: function (progressEvent) {
          const total = progressEvent.total || size;
          const loaded = progressEvent.loaded || 0;
          const progress = loaded / total * 100;
          dispatch({
            type: POST_VIMEO_PROGRESS,
            payload: {
              uploading: true,
              progress: progress >= 100 ? 99 : progress
            }
          });
        }
      });
      return success(videoUri, videoData, size, uploadLink, result);
    };

    try {
      dispatch({
        type: POST_VIMEO_START,
        payload: {
          uploading: true
        }
      });
      const { videoData, size, uploadLink, videoUri, status } = postBody;

     
      if (status !== 'success') {
        return failed();
      }
    

      // start to upload
      return patchVimeoFunc(videoUri, uploadLink, videoData, size);
    } catch (err) {
      console.log(`post vimeo err: ${err}`);
      return failed();
    }
  };
};