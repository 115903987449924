import React from "react";

const AuthContext = React.createContext({
  user: {
    name: "",
    email: "",
    verified: "",
    active: "",
    role: "",
    profilePic: "",
    sessionId: "",
    _id: ""
  },
  isAuthenticated: true,
  updateUser: (usr: any) => {

  },
  logout: () => { }
});

export default AuthContext;