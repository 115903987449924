let url = "";
let kidsURL = "";
let socketURL = "";
if (process.env.NODE_ENV === "development") {
  url = "http://localhost:3001/api/";
  socketURL = "http://localhost:3001";
  kidsURL = "http://localhost:3000";
} else if (process.env.NODE_ENV === "production") {
  url = "https://lmsapi.ttrainer.org/api/";
  socketURL = "https://lmsapi.ttrainer.org";
  kidsURL = "https://lms.ttrainer.org";
}

export const host = url;
export const socket = socketURL;
export const liveClass = kidsURL;
export const blocklyUrl = "https://blockly.ttrainer.org/";
//profile
export const kidsProfile = "student/profile/getbyparentid/";
export const userProfile = "user/profile/";
export const programsApi = "programs/";
export const conceptsApi = "concepts/";
export const challengesApi = "challenges/";
export const assignmentApi = "assignment/";
export const getAssignmentProgId = "getallassignments/byprogid/";
export const evalAssignment = "eval/assignment/";
export const getAssignmentSubmission = "get/assignmentsubmission/";
export const getAssignmentSubmissionAdmin = "get/assignmentsubmissionadmin/";
export const totalAssignSubmissionProgId = "get/totalassignmentsubmission/";
export const reviewAssignmentAdmin = "review/assignsubmissionadmin/";
export const getConceptByProgramId = "get/byid/";
export const getProfileWithPrograms = "get/getProfileWithPrograms";
export const getAllConceptByProgramId = "getall/byprogid/";
export const getAllChallengesByProgramId = "getallchallenges/byprogid/";
export const updateProfileWithChallenges = "update/profilewithchallenges/";
export const updateProfileWithConcpets = "update/profilewithconcepts/";
export const getProfileWithProgress = "get/profilewithprogress/";
export const createTrial = "student/createtrial/";
export const evalScore = "get/evalscore/";
export const getProgamTrial = "get/program/type/";

export const retakeChallenges = "retake/profileChallenges/";

//teachers

//paymnet
export const pending = "pending/";
export const payment = "payment/";
export const cancelPayment = "cancel/";
export const applyCoupon = "coupon/";
//admin
export const adminApi = "admin/";
export const userSession = "usersession/";
export const getAnalytics = "get/analytics/";
export const allStudents = "all/students/";
export const enrolledStudents = "all/enrolledstudents/";
export const enrolledStudentsData = "all/enrolledstudentsdata/";
export const graduatedStudents = "all/graduatedstudents/";
export const graduatedStudentsData = "all/graduatedstudentsdata/";
export const unenrolledStudents = "all/notenrolled/";
export const unenrolledStudentsData = "all/notenrolleddata/";
export const getProgramOptions = "all/programoptions/";
export const getConceptOptions = "all/conceptoptions/";
export const getTeacherOptions = "all/teacheroptions/";

// lms
export const program = "program/";
export const level = "module/";
export const classDataApi = "classes/";
//auth nd other
export const live = "live/";
export const expired = "expired/";
export const certificate = "certificate/";
export const mycertificates = "mycertificates/";
export const account = "auth/";
export const contact = "contact/";
export const coupon = "coupon/";
export const login = "login/";
export const user = "user/";
export const profile = "profile/";
export const register = "register/";
export const invite = "invite/";
export const classroom = "classroom/";
export const room = "room/";
export const resetpassword = "resetpassword/";
export const forgotpassword = "forgotpassword/";
export const updatepassword = "updatepassword/";
export const updateeditor = "updateeditor";
export const verifyemail = "verifyemail";
export const assignment = "assignment/";
export const assessment = "assessment/";
export const changestatus = "changestatus/";
export const addassesmentquestion = "addassesmentquestion/";
export const addassesmentquestionsubjective = "addassesmentquestionsubjective/";
export const evaluate = "eval/";
export const check = "check/";
export const submissions = "submissions/";
export const editquestionsubjective = "editquestionsubjective/";
export const editquestion = "editquestion/";
export const deletequestion = "deletequestion/";
export const deletequestionsubjective = "deletequestionsubjective/";
export const getonebyid = "getonebyid/";
export const admin = "admin/";
export const student = "student/";
export const teacher = "teacher/";
export const programmanager = "programmanager/";
export const create = "create/";
export const cohort = "cohort/";
export const all = "all/";
export const short = "short/";
export const teachers = "teachers/";
export const cohorts = "cohorts/";
export const students = "students/";
export const analytics = "analytics/";
export const createrepo = "createrepo/";
export const assignments = "assignments/";
export const getall = "getall";
export const repocode = "repocode/";
export const getfile = "getfile/";
export const submitreview = "submitreview/";
export const get = "get/";
export const managers = "managers";
export const assessments = "assessments/";
export const review = "review/";

export const currencyOptions = [
  { key: "€", value: "€", text: "€" },
  { key: "£", value: "£", text: "£" },
  { key: "$", value: "$", text: "$" },
  { key: "¥", value: "¥", text: "¥" },
  { key: "₹", value: "₹", text: "₹" },
];

export const categoryOptions = [
  { key: "0", value: "Beginner", text: "Beginner" },
  { key: "1", value: "Intermediate", text: "Intermediate" },
  { key: "2", value: "Advance", text: "Advanced" },
];
