import React, { useEffect } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paper: {
  },
  images: {
    borderRadius: 50,
  },
}))



export default function AutoGridNoWrap(props) {
  const classes = useStyles()
  const [data, setData] = React.useState([
  ])
  const [value, setValue] = React.useState(2);
  useEffect(() => {
    setData(props.reviews)
  }, [props.reviews])
  return (
    <div className={classes.root}>

      {data && data.map((data,i) => (
        <div className={classes.paper} key={i}>
          <Grid container wrap='nowrap' spacing={2}>
            <Grid item>
              <img className={classes.images} src={data.image} />
            </Grid>
            <Grid item xs>
              <Typography><b>{data.userId.name}</b></Typography>
              <Rating name="read-only" value={data.rating} readOnly />
              {/* <ListItemText secondary='Jan 9, 2014' /> */}
              <p>{data.review}</p>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  )
}
