import React from 'react';
import classnames from 'classnames';;

// interface TextField {
//     name: "",
//     type: "",
//     placeholder: "",
//     handleChange: () => void,
//     value: "",
//     info: "",
//     disabled: "",
//     error: ""
// }

// interface TextFieldState {
//     name: ""
// }

function CustomTextField<TextField, TextFieldState>({
    name,
    type,
    placeholder,
    value,
    disabled,
    onChange,
    error,
    className,
    info,
}: any) {
    // console.log(error);

    return (
        <>
            <input
                className={classnames(`form-control ${className}`, {
                    "is-invalid": error
                })}
                onChange={onChange}
                type={type || "text"}
                placeholder={placeholder}
                name={name}
                disabled={disabled}
                value={value}
            />
            {info && <small className="form-text text-muted">{info}</small>}
            {error && <div className="invalid-feedback">{error}</div>}
        </>
    )
}


export default CustomTextField;