import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab/';
import Axios from 'axios';
import {
    Card, CardContent, LinearProgress
} from '@material-ui/core';
import { account, host } from '../../utils/constants';
import { Navbar } from '../common';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
    center: {
        margin: "auto",
        width: "350px",
        textAlign: "center",
        marginTop:  isMobile ? "20%" : "8%"
    },
    heading: {
        fontWeight: 900,
        textTransform: "uppercase"
    }
});



export default function VerifyEmail(props: any) {
    const classes = useStyles();
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        Axios.get(`${host}${account}confirmation/${props.match.params.token}`)
            .then(res => {
                setLoading(false)
                setMessage(res.data.message)
            })
            .catch(err => {
                setLoading(false)
                if (err.response) {
                    setError(err.response.data.message)
                } else {
                    if (err.message === "Network Error") {
                        setError(`${err.message} - Please check your internet connection`)
                    } else {
                        setError(err.message)
                    }
                }
            })
    }, [props.match.params.token])
    return (
        <>
        <Navbar {...props}/>
        <Card className={classes.center}>
            {loading && <LinearProgress />}
            <CardContent>
                {message && <Alert severity="info">{message}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
            </CardContent>
        </Card>
        </>
    );
}



// <div>
// <NavBar />
// {message.success.length !== 0 && <Message className="text-center w-50 mx-auto mt-5" positive>
//     <p>{message.success}</p>
// </Message>}
// {message.error.length !== 0 && <Message className="text-center w-50 mx-auto mt-5" negative>
//     <p>{message.error}</p>
// </Message>}
// {loading && <Dimmer active inverted>
//     <Loader inverted>Verifying Token Please Wait.</Loader>
// </Dimmer>}
// <Footer s={{ bottom: "0", position: "fixed", width: "100%" }} />
// </div>