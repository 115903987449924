import {GET_PROGRAMS , GET_PROGRAM ,RESET_PROGRAM , GET_INSTRUCTOR_PROFILE} from '../actions/types'

const initialState = {
    programs : [],
    program : {} ,
    isProgramsLoading : true,
    isProgramLoading : true,
    isInsProfileLoading : true,
    instructorProfile : {}
}

export const programsReducer=(state:any = initialState, action: any)=> {
    switch (action.type) {
        case GET_PROGRAMS:
            return {
                ...state,
                programs: action.payload,
                isProgramsLoading : false
            }
        case GET_PROGRAM:
            return {
                ...state,
                program: action.payload,
                isProgramLoading : false
            }
        case GET_INSTRUCTOR_PROFILE:
            return {
                ...state,
                instructorProfile: action.payload,
                isInsProfileLoading: false
            }
        case RESET_PROGRAM : 
            return {
                ...state,
                program : {},
                isProgramLoading : true
            }
        default:
            return state;
    }
}