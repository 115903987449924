import axios from "axios";
// import Snackbar from '@material-ui/core/Snackbar';

export const setAuthToken = (token: any) => {
    if (token) {
        // Apply every request
        axios.defaults.headers.common["Authorization"] = token;
    } else {
        // Delete auth header
        delete axios.defaults.headers.common["Authorization"];
    }
};

// export const axiosGlobalErrorHandlerConfig = () => {
//     axios.interceptors.request.use(req => req)
  
//     axios.interceptors.response.use(res => res, err => {
//       // console.log(err);
  
//       if (err.response) {
//         if (err.response.status === 401) {
//           localStorage.removeItem("jwtToken");
//           localStorage.setItem("sessionExpired", "true");
//           window.location.href = "/";
//           return Promise.reject("");
//         }
//       } else {
//         if (err.message === "Network Error") {
//           showToast("error", err.message, "Please check your internet connection");
//         } else {
//           showToast("error", err.message, "Unexpected error occured.");
//         }
//       }
  
//       return Promise.reject(err);
//     })
//   }

export const secondsToHm = (seconds: any) => {
    seconds = Number(seconds);
    let h = Math.floor(seconds / 3600);
    let m = Math.floor(seconds % 3600 / 60);

    let hDisplay = h > 0 ? h + ":" : "00:";
    let mDisplay = m > 0 ? m : "00";
    return hDisplay + mDisplay;
}

// export const showToast = (
//     type: "info" | "error" | "success" | "warning" | undefined,
//     title: string,
//     message: string) => {
//     if (type === "error") {
//       if (message === "Network Error") {
//         toast({
//           type,
//           title,
//           description: "Please check your internet connection",
//           time: 4000
//         })
//       } else {
//         toast({
//           type,
//           title,
//           description: message,
//           time: 4000
//         });
//       }
//     } else {
//       toast({
//         type,
//         title,
//         description: message,
//         time: 4000
//       });
//     }
//   }