import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
    },
  });

const Oauth = (props: any) => {
    const classes = useStyles();
    return (
        <h1 className={classes.root}>Oauth</h1>
    )
}

export default Oauth;