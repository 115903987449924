import React, { useState } from "react";
import StyleContext from "./StyleContext";

export default function AuthProvider(props: any) {
    const [styles, setStyle] = useState({
        style:{
            rootBackgroundColor: "white",
            rootBackgroundHeight: "100vh"
        },
        updateStyles: (st: any) => {},
    });
    return (
        <StyleContext.Provider
            value={{
                ...styles,
                updateStyles: (st: any) => {
                    setStyle(st);
                }
            }}
        >
            {props.children}
        </StyleContext.Provider>
    );
}