import React  from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
  } from '@material-ui/core';
  import { Close } from '@material-ui/icons';
  
  const ConfirmDialog = (props : any) => {
    const {dialog, close , onSubmit , message} = props
    

    return (
      <Dialog open={Boolean(dialog)} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm the action</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={close}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained"  onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            close();
          }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ConfirmDialog;