import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { YellowLogo } from "../../images";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: "100%",
      padding: "25px 0",
      backgroundColor: "#F8F9FA",
      borderTop: "1px solid #dadce0",
      borderBottom: "1px solid #dadce0",
      marginTop: "22%",
    },
    footerLogo: {
      height: "50px",
    },
    footerNav: {
      position: "relative",
      backgroundColor: "inherit",
      boxShadow: "0px 0px 0px 0px white",
    },
    followus: {
      marginLeft: "auto",
    },
    footerIcon: {
      height: "35px",
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <AppBar className={classes.footerNav}>
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit">
            <img
              className={classes.footerLogo}
              src="https://res.cloudinary.com/ddbapnomm/image/upload/v1700418704/ttrainer.org_Logo_n22bzu.webp"
              alt="ttrainer.org"
            />
          </Typography>
          <Link className="link p-5" to="/about">
            Terms
          </Link>
          <Link className="link p-5" to="/privacy">
            Privacy Policy
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
}
