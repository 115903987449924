import React, { useState } from 'react';
import Axios from 'axios'
import {
    createStyles, Theme, makeStyles
} from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import {
    Navbar
} from '../common';
import { Alert } from '@material-ui/lab/';
import {
    CardContent, FormControl, Typography,
    Card, Button, LinearProgress,
    CircularProgress
} from '@material-ui/core';

import { host, account, forgotpassword } from '../../utils/constants'

const useStyles = makeStyles((theme: Theme) => createStyles({
    center: {
        margin: "auto",
        width: isMobile ? "330px" : "400px",
        textAlign: "center",
        marginTop:  isMobile ? "20%" : "8%"
    },
    heading: {
        fontWeight: 900,
        textTransform: "uppercase",
        marginTop: "1%"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    btn: {
        background: "#0092CC",
        color: "white",
        // boxShadow: "5px 5px 0px 0px #0092CC",
        borderRadius: "0px",
        outline: "1px solid black",
        '&:hover': {
            backgroundColor: "#0092CC",
            color: "white !important",
            boxShadow: "0px 0px 0px 0px #0092CC",
        }
    }
})
);

const ForgotPassword = (props: any) => {
    const classes = useStyles();

    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setLoading(true);

        const data = {
            email
        }

        Axios.post(`${host}${account}${forgotpassword}`, data)
            .then(res => {
                setLoading(false)
                setMessage(res.data.message);
                setEmail("")
            })
            .catch(err => {
                setLoading(false)
                if (err.response) {
                    setError(err.response.data.message);
                } else {
                    if (err.message === "Network Error") {
                        setError(`${err.message} - Please check your internet connection`);
                    } else {
                        setError(err.message)
                    }
                }
            })
    }

    return (
        <>
            <Navbar {...props} />
            <Card className={classes.center}>
                {loading && <LinearProgress color="secondary" />}
                <CardContent>
                    {message && <Alert severity="success">{message}</Alert>}
                    {error && <Alert severity="error">{error}</Alert>}
                    <Typography className={classes.heading} variant="h5" align="center">Forgot Password</Typography>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <FormControl>
                            <input className="form-control" type="email"
                                placeholder="Email" name="email"
                                value={email} onChange={(e) => setEmail(e.target.value)} />
                            <br />
                            <Button
                                className={classes.btn}
                                type="submit" disabled={loading}
                                endIcon={loading && <CircularProgress size={15} />}>Submit </Button>
                            <br />
                        </FormControl>
                    </form>
                </CardContent>
            </Card>
        </>
    )
}

export default ForgotPassword;