import { combineReducers } from 'redux';
import { programsReducer } from './programsReducer'
import { conceptsReducer } from './conceptsReducer'
import { editorReducer } from './editorReducer'
import { videoData } from '../components/admin/Concept/reducer'


export const rootReducer = combineReducers({
   programsState: programsReducer,
   conceptsState: conceptsReducer,
   editorState: editorReducer,
   videoData : videoData
})

export type RootState = ReturnType<typeof rootReducer>