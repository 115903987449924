import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
//local components import
import Routers from './Router';
import {
  BrowserRouter as Router
} from "react-router-dom";
import { AuthProvider, StyleProvider } from './context'
//redux
import { Provider } from 'react-redux';
import store from './store';

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif !important",
  }
});

function App() {
  return (

    <Provider store={store}>
      <StyleProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
              <Routers />
          </ThemeProvider>
        </AuthProvider>
      </StyleProvider>
    </Provider>

  );
}

export default App;
