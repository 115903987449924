import React from "react";

const StyleContext = React.createContext({
    style:{
        rootBackgroundColor: "",
        rootBackgroundHeight: ""
    },
    updateStyles: (st: any) => {},
});

export default StyleContext;