import React, { useState, useEffect , useContext } from 'react'
import axios from 'axios'
import { Container } from '@material-ui/core'
import {Loading , Navbar} from '../common/'
import { useHistory, useParams } from 'react-router-dom';

import success from '../../images/success.gif'
import { host, payment } from '../../utils/constants'
import Alert from '../general/alert'
import { AuthContext } from '../../context';
interface ParamTypes {
    id: string
  }
export default function PaymentSuccess() {
    const { push } = useHistory();
    const { id } = useParams<ParamTypes>()
    const { isAuthenticated } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")
    const [alert, setAlert] = useState({
        success: "",
        error: ""
    });

    useEffect(() => {
       if(isAuthenticated){
        const url = `${host}${payment}`;
        const data = { stripeSessionId: id }
        setLoading(true);
        axios.put(url, data)
            .then(res => {
                setLoading(false);
                setTimeout(() => {
                    push("/student/dashboard/")
                }, 5000);
            })
            .catch(err => {
               
                
                setLoading(false);
                if (err.response) {
                    if (err.response.data) {
                        setError(err.response.data)
                        setAlert({
                            success: "",
                            error: err.response.data
                        })
                    }
                } else {
                    if (err.response.data.message) {
                        setError(err.response.data.message)
                        setAlert({
                            success: "",
                            error: err.response.data.message
                        })
                    }
                }
            })
       }
    }, [isAuthenticated])
    
    return (
        <>
            <Navbar />
            <Alert alert={alert} />
            <Container>
                <div className="text-center" style={{ marginTop: "100px" }}>
                    {loading
                        ? <div >
                            <p>
                                <h3>Just one second</h3>
                                We are verifying your payment status...
                        </p>
                            <Loading />
                        </div>
                        : error
                            ? <div className="text-center" >
                                <p>{error}</p>
                            </div>
                            : <>
                                <img src={success} alt="success" />
                                <br></br>
                                <h4>
                                    Payment confirmation mail will be sent to your email address.
                                    Please wait while we redirect you...
                            </h4>
                            </>
                    }
                </div>
            </Container>
        </>
    )
}
