import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Alert } from '@material-ui/lab/';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
    Card, CardContent, FormControl,
    Button, Typography, LinearProgress,
    CircularProgress
} from '@material-ui/core';

import {
    Navbar
} from '../common';

import {
    host, account, resetpassword,
    updatepassword
} from '../../utils/constants'
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => createStyles({
    center: {
        margin: "auto",
        width: isMobile ? "330px" : "350px",
        textAlign: "center",
        marginTop:  isMobile ? "20%" : "5%"
    },
    heading: {
        fontWeight: 900,
        textTransform: "uppercase"
    },
    resetBtn: {
        background: "#0092CC",
        color: "white",
        // boxShadow: "5px 5px 0px 0px #0092CC",
        borderRadius: "0px",
        outline: "1px solid black",
        '&:hover': {
            backgroundColor: "#0092CC",
            color: "white !important",
            boxShadow: "0px 0px 0px 0px #0092CC",
        }
    }
})
);

const ResetPassword = (props: any) => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const { id } = props.match.params;

    useEffect(() => {
        setLoading(true);
        const url = `${host}${account}${resetpassword}${id}`;

        Axios.get(url)
            .then(res => {
                setLoading(false)
                setEmail(res.data.email);
                setToken(res.data.token);
                setMessage(res.data.message);
            })
            .catch(err => {
                setLoading(false)
                if (err.response) {
                    setError(err.response.data.message)
                } else {
                    if (err.message === "Network Error") {
                        setError(`${err.message} - Please check your internet connection`)
                    } else {
                        setError(err.message)
                    }
                }
            })
    }, [id])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            email,
            password,
            password2,
            token
        }

        Axios.put(`${host}${account}${updatepassword}`, data)
            .then(res => {
                setLoading(false);
                setMessage(res.data.message);
                setPassword("");
                setPassword2("");
            })
            .catch(err => {
                setLoading(false)
                setError(err.response.data.message)
            })
    }

    return (
        <>
        <Navbar {...props}/>
        <Card className={classes.center}>
            {loading && <LinearProgress />}
            <CardContent>
                {message && <Alert severity="success">{message}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
                <Typography className={classes.heading} variant="h5" align="center">Reset Password</Typography>
                <br />
                <form onSubmit={handleSubmit}>
                    <FormControl>
                        <input
                            className="form-control" type="password"
                            placeholder="Password" name="password"
                            value={password} onChange={e => setPassword(e.target.value)} />
                        <br />
                        <input
                            className="form-control" type="password"
                            placeholder="Confirm Password" name="password2"
                            value={password2} onChange={e => setPassword2(e.target.value)} />
                        <br />
                        <Button
                            className={classes.resetBtn}
                            type="submit" disabled={loading}
                            endIcon={loading && <CircularProgress size={15} />}>
                            Reset
                        </Button>
                        <br />
                    </FormControl>
                </form>
            </CardContent>
        </Card>
        </>
    )
}

export default ResetPassword;