import React, { useState } from "react";
import Axios from "axios";
import {admin, host} from "../utils/constants";
import AuthContext from "./AuthContext";

export default function AuthProvider(props: any) {
    const [user, setUser] = useState({
        user: {
            name: "",
            email: "",
            mobileNumber: "",
            verified: "",
            active: "",
            role: "",
            profilePic: "",
            _id: "",
            sessionId: ""
        },
        isAuthenticated: false,
        updateUser: (usr: any) => {},
        logout: () => {}
    });

    return (
        <AuthContext.Provider
            value={{
                ...user,
                updateUser: (usr: any) => {
                    setUser(usr);
                },
                logout: async () => {
                    try {
                        const url = `${host}${admin}usersession/${user.user.sessionId}`;
    
                        await Axios.put(url, {endTime: new Date()});
                    } catch (error) {
                        console.log(error);
                    }

                    localStorage.removeItem("jwtToken");
                    window.location.href = "/";
                }
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}