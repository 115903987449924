import React, { useState, useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import {
    Navbar, Footer, Loading
} from '../common';
import { Container, Grid, Card, CardMedia, CardContent, Table, TableBody, TableCell, TableHead, TableRow, Modal, Backdrop, Fade, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers'
import { getProgram, resetProgram, getConceptsByProgramId, resetConcepts } from '../../actions/index'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router';
import Enroll from './Enroll'
import Alert from '../general/alert'
import { isMobile } from 'react-device-detect';
import Reviews from './Reviews'
import axios from 'axios'
import { host, userProfile, createTrial } from '../../utils/constants'
import {CustomizedSnackbars} from '../general'
// const stripePromise = loadStripe(
//     'pk_test_51HTlRLAiS9wDlMPpTgBsHsgF5qDIjaa1VyGGcl90GqBLS5Ip0miYbRGk8wo80Xo48l2pFMk5NHYwRXsXtD9MTgQE006wunqMnz')
const stripePromise = loadStripe(
        'pk_live_51HTlRLAiS9wDlMPpzyImfOIi3OCVqKZyyJgTXhEgpe5yS03bVmnjiUrLzcwMs0QWxwXsbyezbe5reZE6tn7TRfYr00cPvYRhr1')
// const StyledTableCell = withStyles((theme: Theme) =>
//     createStyles({
//         head: {
//             backgroundColor: theme.palette.common.black,
//             color: theme.palette.common.white,
//         },
//         body: {
//             fontSize: 14,
//         },
//     }),
// )(TableCell);

// const StyledTableRow = withStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             '&:nth-of-type(odd)': {
//                 backgroundColor: theme.palette.action.hover,
//             },
//         },
//     }),
// )(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainDiv: {
            marginTop: "60px"
        },
        heading: {
            marginBottom: "0px"
        },
        videoDemo: {

        },
        previewImg: {
            height: "150px",
            width: "80%",
            overflow: "hidden",
            margin: "auto"
        },
        videoDiv: {
            marginTop: "25px"
        },
        cardMedia: {
            height: 200,
            borderBottom: "1px solid rgba(0,0,0,.125)",
        },
        card: {
            borderRadius: "0px"
        },
        play: {
            backgroundImage: "url('https://www.freeiconspng.com/uploads/video-play-icon-11.png')",
            height: "200px",
            width: "100%",
            backgroundSize: "50px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
            marginTop: "-200px",
            transition: "background-size 0.2s",
            '&:hover': {
                backgroundSize: "60px"
            }
        },
        content: {
            paddingRight: "25px"
        },
        contentDesc: {
            marginTop: "2px"
        },
        arrow: {
            // height: "20px",
            position: "relative",
            marginTop: "5px",
            margin: "0 5px 0 -15px",
            '&:before': {
                content: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAABZElEQVQ4jc3UPU/CQBwG8OdoyXUhomEgyGI1USMuBv0SAm5IiInfTVAjJvAVjMokylZ8oTgIJg5iWxauIOdgqE3akoILz/a/Xn99LrkUmPcQ+1B/rYcZZVnOyaKvlwn/EiXxIrmU1B3gL2Y+AFiZslRLlISdMRoYr5rUzM2AAYA87A8Px4MFjjiiM2AAAM5JzAFOE41pKDcrYN/M8WxqUGMaCsoZGt0nvPXa/wN1U0excQ5jYCAjp7AWXvUHcs6hfD46mp0oReimjgM5je1IwvWjruBNp4pys4Kr9rWFFRqnMMweMnIKiciW5ylEt8W96C5UXcVtpwo27ONZe7Ewr2YTG0oiRX4jh+VQDHcf974xTxAAqECRX88hHoojLe/7wgCPI9vR480jEEImbXNvSAh/d9vgCyOwLqQFUkZLAFq+q1gW1KAkXNrmv9S6tYVBf5QNEO68sS4ZcaIGpUDJ/vua//wAP6SEPyc2v7sAAAAASUVORK5CYII=')",
                top: "4px",
                position: "relative",
                height: "10px"
            }
        },
        ol: {
            listStyleType: "none"
        },
        h3m0: {
            margin: "0px"
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: "black",
            padding: theme.spacing(2, 4, 3),
            borderRadius: "0px"
        },
        vidTitle: {
            color: "white"
        },
        ageGrp: {
            marginTop: "0px",
            marginBottom: "0px",
            fontSize: "15px",
            color: "gray"
        },
        ctaEnroll: {
            borderRadius: "0px",
            backgroundColor: "#4285f4",
            color: "white",
            '&:hover': {
                borderRadius: "0px",
                backgroundColor: "#4285f4",
                color: "white",
            }
        },
        pbold: {
            fontWeight: "bold",
            fontStyle: "normal"
        }
    }),
);


interface ParamTypes {
    id: string
}
export default function Home(props: any) {
    const { history } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const programState = useSelector((state: RootState) => state.programsState.program)
    const isProgramLoading = useSelector((state: RootState) => state.programsState.isProgramLoading)
    const conceptsState = useSelector((state: RootState) => state.conceptsState.concepts)
    const isConceptsLoading = useSelector((state: RootState) => state.conceptsState.isConceptsLoading)
    const { id } = useParams<ParamTypes>()
    const [alert, setAlert] = useState({
        success: "",
        error: ""
    });
    useEffect(() => {
        dispatch(getProgram(id))
        setLoading(false)
        return () => {
            dispatch(resetProgram)
        }
    }, [id])
    useEffect(() => {
        dispatch(getConceptsByProgramId(id))
        return () => {
            dispatch(resetConcepts)
        }
    }, [id])
    const [curVideo, setCurVideo] = useState({
        title: "",
        videoUrl: "",
        type: "",
        index: 0,
        isPreview: false
    })
    const [videoModal, setVideoModal] = useState(false)

    const playVideo = (v: any) => {
        if (v) {
            setCurVideo(v);
        }
        setVideoModal(true);
    }
    const enroll = () => {
        if (!localStorage.jwtToken) {
            localStorage.setItem("enroll", id)
            return history.push("/login")
        }

        setOpen(true);
    }
    //Modal 

    // const handleModalOpen = () => {
    //     setOpen(true);
    // };
    const handleModalClose = () => {
        setOpen(false);
    };



    const enrollRedirect = () => {
        const url = `${host}${userProfile}${createTrial}${id}`
        axios.post(url)
            .then((res: any) => {
                setOpenSnackbar(true)
                setAlert({
                    success: res.data.message,
                    error: ""
                })
                setLoading(false);
                setTimeout(() => {
                   return history.push('/student/dashboard')
                }, 1500);
                
            })
            .catch((err: any) => {
                setOpenSnackbar(true)
                setAlert({
                    success: "",
                    error: err.response.data.message
                })
                setLoading(false);
            })
    }

    const handleClose = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <> 
            <Navbar {...props} />
            <Elements stripe={stripePromise}>
                <CustomizedSnackbars alert={alert} open={openSnackbar} handleClose={handleClose}/>
                <Container className={classes.mainDiv}>
                    <Alert alert={alert} />
                    {isProgramLoading === true ? <Loading /> : <Grid container>
                        <Grid item xs={12} sm={8}>
                            <div className={classes.content}>
                                <h1 className={classes.heading}>{programState.name}</h1>
                                <Rating name="read-only" size="small" value={programState.ratings} readOnly />
                                <p className={classes.ageGrp}>{programState.ageGroup}</p>
                                <h3 className={classes.contentDesc}>{programState.description}</h3>
                                <h2>What will they learn?</h2>
                                <ol className={classes.ol}>
                                    {programState.highlights.map((h: any, i: any) => {
                                        return <li className={classes.arrow} key={i}>
                                            {` `}{h}
                                        </li>
                                    })}
                                </ol>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.videoDiv}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        component="img"
                                        className={classes.cardMedia}
                                        src={programState.programImage}
                                        alt={programState.name}
                                    />
                                    <div className={classes.play} onClick={() => playVideo(programState.previewVideo)}></div>
                                    <CardContent>
                                        <h3 className={classes.h3m0}>This program includes</h3>
                                        <ol className={classes.ol}>
                                            {programState.previewVideo.title.map((h: any, i: any) => {
                                                return <li className={classes.arrow} key={i}>
                                                    {` `}{h}
                                                </li>
                                            })}
                                        </ol>
                                        <div className="text-center">
                                            {programState.isTrial ? <Button className={classes.ctaEnroll} onClick={enrollRedirect}>Enroll Now</Button>
                                                : <Button className={classes.ctaEnroll} onClick={enroll}>Enroll Now</Button>
                                            }
                                        </div>
                                    </CardContent>
                                </Card>
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={videoModal}
                                    onClose={() => setVideoModal(false)}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={videoModal}>
                                        <div className={classes.paper}>
                                            <ReactPlayer
                                                url={curVideo.videoUrl}
                                                playing
                                                height={isMobile ? "200px" : "360px"}
                                                width={isMobile ? "270px" : "640px"}
                                                config={{
                                                    file:
                                                    {
                                                        attributes: {
                                                            controlsList: 'nodownload',
                                                            onContextMenu: (e: any) => e.preventDefault()
                                                        }
                                                    },
                                                    vimeo: {
                                                        playerOptions: {
                                                            controls: "true"

                                                        }

                                                    }

                                                }}


                                            />
                                        </div>
                                    </Fade>
                                </Modal>
                            </div>
                        </Grid>
                    </Grid>
                    }
                    {isConceptsLoading ? <Loading /> : <ConceptsContainer conceptsState={conceptsState} playVideo={playVideo} />}
                    <br />

                    <h2>Student feedback</h2>
                    {programState.reviews && <Reviews reviews={programState.reviews} />}
                    <Enroll
                        open={open}
                        handleModalClose={handleModalClose}
                        loading={loading}
                        programState={programState}
                        setAlert={setAlert}
                    />

                </Container>
            </Elements>
            <Footer />
        </>
    )
}

function ConceptsContainer(props: any) {



    const { playVideo, conceptsState } = props



    if (conceptsState.length <= 0) return null
    return (
        <>
            <div>
                <h1>Course Content</h1>
                <Table style={{ width: '100%' }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Concept</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Preview</TableCell>
                            <TableCell>Level</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {conceptsState.map((c: any, i: any) => {
                            return <TableRow key={i} >
                                <TableCell>
                                    {c.title}
                                </TableCell>
                                <TableCell>
                                    {c.videoType}
                                </TableCell>
                                <TableCell>
                                    {c.videoType === "video" ? <>{c.isPreview ? <span className="pointer blue" onClick={() => playVideo(c)}>Preview</span> : " No Preview "}</> : <> </>}
                                </TableCell>
                                <TableCell>
                                    {c.category}
                                </TableCell>
                            </TableRow >
                        })}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}

