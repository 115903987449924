import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Container } from '@material-ui/core'
import { Loading, Navbar } from '../common/'
import {  useParams , Link} from 'react-router-dom';
import { host, payment,  } from '../../utils/constants'
import { AuthContext } from '../../context';
interface ParamTypes {
    id: string
}
export default function PaymentFailure() {
   
    const { id } = useParams<ParamTypes>()
    const {  isAuthenticated } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("")

    useEffect(() => {
        if (isAuthenticated) {
            const url = `${host}${payment}`;
            const data = { stripeSessionId: id, failed: true }
            setLoading(true);
            axios.put(url, data)
                .then(res => {
                    setLoading(false);
                    setMessage("Payment failed. Please try again... or")
                })
                .catch(err => {
                    console.log(err.response);
                    setLoading(false);
                    setMessage("Payment failed. Please try again... or")
                })
        }
    }, [isAuthenticated])

    return (
        <>
            <Navbar />
            <Container>
                <div className="text-center" style={{ marginTop: "120px" }}>
                    {loading
                        && <div >
                            <p>
                                <h3>Just one second</h3>
                                We are verifying your payment status...
                        </p>
                            <Loading />
                        </div>}
                    {message
                        && <div>
                            {message === "Payment failed. Please try again... or"
                                ? <>
                                    {message}
                                    <Link to="/student/dashboard">
                                        {" "} Go To Dashboard
                                    </Link>
                                </>
                                : message}
                        </div>
                    }
                </div>
            </Container>
        </>
    )
}
