import { EDITOR_SET_PROGRAMID } from '../actions/types'

const initialState = {
    currentProgramId: ""
}

export const editorReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case EDITOR_SET_PROGRAMID:
            return {
                ...state,
                currentProgramId: action.payload,
            }
        default:
            return state;
    }
}