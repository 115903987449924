import React, { useState } from "react";
import Axios from "axios";
import { Alert } from '@material-ui/lab/';
import {
    Card, CardContent, Button,
    LinearProgress,
    makeStyles, createStyles, Theme
} from '@material-ui/core';

import {
    Navbar
} from '../common';

//local imports
import CustomTextField from "../common/CustomTextField";
import { host, account, verifyemail } from "../../utils/constants";
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles((theme: Theme) => createStyles({
    center: {
        margin: "auto",
        width: "350px",
        textAlign: "center",
        marginTop: isMobile ? "20%" : "9%"
    },
    loginBtn: {
        background: "#03DAC6",
        color: "white",
        // boxShadow: "5px 5px 0px 0px #03DAC6",
        borderRadius: "0px",
        outline: "1px solid black",
        '&:hover': {
            backgroundColor: "#03DAC6",
            color: "white !important",
            boxShadow: "0px 0px 0px 0px #03DAC6",
        }
    },
    heading: {
        fontWeight: 900,
        textTransform: "uppercase"
    }
}));


export default function GenerateToken(props: any) {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        email: ""
    })
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        email: ""
    });

    const onSubmit = (e: any) => {
        e.preventDefault()
        setLoading(true)
        Axios.post(`${host}${account}${verifyemail}`, formData)
            .then(res => {
               
                setLoading(false)
                setMessage(res.data.message)
                setFormData({
                    email: ""
                })
            })
            .catch(err => {
             
                setLoading(false)
                if (err.response) {
                    if (err.response.data.error) {
                        setError(err.response.data.error)
                    } else {
                        setErrors({ ...err.response.data })
                    }
                } else {
                    if (err.message === "Network Error") {
                        setError(`${err.message} - Please check your internet connection`)
                    } else {
                        setError(err.message)
                    }
                }
            })
    }

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    return (
        <>
            <Navbar {...props} />
            <Card className={classes.center}>
                {loading && <LinearProgress color="secondary" />}
                <CardContent>
                    {message && <Alert severity="info">
                        <p>{message}</p>
                    </Alert>}

                    {error && <Alert severity="error">{error}</Alert>}
                    <h2 className="text-center">Generate Verification Email</h2>
                    <br />
                    <form onSubmit={onSubmit}>
                        <CustomTextField
                            className="m-c"
                            type="email"
                            onChange={handleChange}
                            placeholder="Email"
                            name="email"
                            error={errors.email}
                        />
                        <br />
                        <br />
                        <div className="text-center">
                            <Button type="submit"
                                className={classes.loginBtn}
                                variant="contained" >Submit</Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </>
    );
}